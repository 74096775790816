let hostUrl = "https://stage.frogid.com.au/api/user"; // Change to production when ready

switch (process.env.NODE_ENV) {
  case "production":
    hostUrl = "https://www.frogid.net.au/api/user";
    break;
  case "staging":
    hostUrl = "https://stage.frogid.net.au/api/user";
    break;
  case "development":
    hostUrl = "http://localhost:3000/api/user";
    break;
}

export default hostUrl;
